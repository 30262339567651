<template>
  <div class="container">
    <My-Breadcrumb :breadcrumbs="breadcrumbs" />
    <div class="mainBox">

      <!-- 当前版本信息 -->
      <div class="control_box">
        <div class="tools-title" style="padding: 8px 0;">
          <a style="float:left;" type="primary" @click="goBack">
            返回</a>
          <a style="float:right;" @click="showHistoryFn">{{ showHistory ? "查看新的订单" : "查看历史订单" }}  </a>
        </div>
      </div>

      <!-- 版本/模块/硬件 -->
      <div class="show_box">
        <!-- 意向购买/订单列表 -->
        <a-tabs default-active-key="1" @change="changeTab2">
          <!-- 意向购买列表 -->
          
          <!-- 订单列表 -->
          <a-tab-pane key="1" tab="订单与发票管理">
            <a-select :value="status" @change="searchOrders" style="margin-bottom:20px;">
              <a-select-option v-for="item in statuses" :key="item.key" :value="item.key">
                {{item.value}}
              </a-select-option>
            </a-select>
            <!-- 订单与发票管理 -->
            <a-table class="orderTable" :columns="columns2" :data-source="tableData2" :loading="loading2"
              :rowKey="(tableData2) => tableData2.id" :pagination="pagination2" @change="handleTableChange">
              <a-table slot="expandedRowRender" slot-scope="record" :columns="innerColumns"
                :data-source="record.child_order" rowKey="id" :pagination="false">
                <template slot="production_expire" slot-scope="record">
                  {{ record.production_expire ? record.production_expire : "----" }}
                </template>
                <template slot="buy_num" slot-scope="record">
                  {{ record.production_type == 1 && record.buy_num == 0 && record.buy_type == 3 ? '仅升级' : record.production_type == 1 && record.buy_num == 0 && record.buy_type == 4 ? '仅降级' : record.buy_num }}
                </template>
              </a-table>
              
              <template slot="status" slot-scope="record">
                {{ record.status == 1 ? "未支付" : "已支付" }}
              </template>
              <template slot="payment_at" slot-scope="record">
                {{record.payment_at ? record.payment_at : '无'}}
              </template>
              <template slot="payment_mode" slot-scope="record">
                {{record.payment_mode == 1 ? '微信' : record.payment_mode == 2 ? "支付宝" : record.payment_mode == 3 ? '企业网银' : record.payment_mode == 4 ? '对公汇款' : record.payment_mode == 6 ? '快捷支付' : '无'}}
              </template>
              <template slot="action" slot-scope="record">

                  <a-button v-if="record.status == 1 && record.payment == 0" type="link" @click="showKeFuCode()">联系客服改价</a-button>
                  <a-button v-if="record.status == 1 && record.payment != 0" type="link" @click="payFromOrders(record)">支付</a-button>
                  <a-divider v-if="record.status == 1" type="vertical" />
                  <a-button v-if="record.status == 1" type="link" @click="removeOrderFn(record)">移除订单</a-button>
                  <a-divider v-if="record.status == 1" type="vertical" />

                  
                
                <a-button
                  v-if="record.status == 2 && record.get_invoice == null || (record.get_invoice != null && record.get_invoice.state == 4)"
                  type="link" @click="showApplyDetailFn(record)">开具发票</a-button>
                <a-button
                  v-if="record.status == 2 && (record.get_invoice != null && record.get_invoice.state == 2) || (record.get_invoice != null && record.get_invoice.state == 3)"
                  type="link" @click="showApplyDetailFn(record)">发票详情</a-button>
                <a-divider v-if="record.refund_order.length != 0" type="vertical" />
                <a-button v-if="record.refund_order.length != 0" type="link" @click="showRefundFn(record.refund_order)">
                  退款记录</a-button>

              </template>
            </a-table>
          </a-tab-pane>
        </a-tabs>
      </div>

    </div>

    <!-- 选择支付方式弹窗 -->
    <a-modal v-model="selectPayType" title="选择支付方式" :keyboard="false" :maskClosable="false" :footer="null"
      @cancel="noSurePayType">
      <div style="color:red;">购买系统版本或者增值功能完成后请重新登陆生效</div>
      <a-radio-group v-model="payType" @change="changePayType">
        <a-radio v-for="item in payTypes" :key="item.key" :value="item.key">
          {{item.value}}
        </a-radio>
      </a-radio-group>

      <div style="text-align: center" v-show="showWxScan">
        <!-- 装微信支付二维码的容器 -->
        <div style="display: flex; justify-content: center;marginTop:10px;" class="qrcode" ref="qrCodeUrl"></div>
        <p style="marginTop:10px;">
          <img src="https://vip.51baocan.com/static/home/pay/img/step.png" alt srcset />
        </p>
        <a-button icon="transaction" style="background-color: #00c800; color: white; margin: 15px auto"
          @click="wxPaySuccess">支付成功</a-button>
      </div>
      <div id="aliPay"></div>
      <div id="unionPay"></div>
      <div id="quickPay"></div>
    </a-modal>

    <!-- 对公汇款 -->
    <a-modal v-model="showUnderLine" title="对公汇款订单详情可在订单与发票管理中查看" :footer="null" :width="400">
      <div style="text-align: center">
        <div>
          <a-row>
            <a-col :span="5" style="textAlign:right;">
              开户名称:
            </a-col>
            <a-col :span="19" style="textAlign:left;paddingLeft:20px;">
              福建尚汇信息科技有限公司
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="5" style="textAlign:right;">
              开<i style="paddingLeft:7px;" />户<i style="paddingLeft:7px;" />行:
            </a-col>
            <a-col :span="19" style="textAlign:left;paddingLeft:20px;">
              中国建设银行股份有限公司漳州东城支行
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="5" style="textAlign:right;">
              账<i style="paddingLeft:26px;" />号:
            </a-col>
            <a-col :span="19" style="textAlign:left;paddingLeft:20px;">
              35050166810700001235
            </a-col>
          </a-row>
        </div>
        <p>
          <img :src="kefuSrc" alt srcset style="width: 180px;margin-top: 20px;" />
        </p>
        <p>请及时添加客服微信或者联系客服电话:</p>
        <p>4009-180-190</p>
      </div>
    </a-modal>

    <!-- 协议 -->
    <a-modal v-model="showRule" title="“食堂有饭”系统及服务使用规则" :width="1000" @ok="showRule = false">

      <p>
        “食堂有饭”系统及服务（以下简称“食堂有饭”），是指福建尚汇信息科技有限公司策划实施的“食堂有饭”SAAS平台服务体系。“食堂有饭”小程序、食堂管理后台及相关硬件拓展、平台上的第三方服务、网站、公众号及定制服务、现场服务等相关内容是该服务体系的一部分。
      </p>

      <p>“用户”是指入驻“食堂有饭”，开通“食堂有饭”具体食堂后台管理权限的单位、组织、团体、机构、商家等非个人用户。</p>

      <p>用户使用“食堂有饭”之前，应完整阅读、理解且同意本规则、愿意遵守本规则。</p>

      <p> 一、本规则内容包括“食堂有饭”已经发布或将来可能发布的各项规则，所有规则为本规则不可分割的部分，与本规则具有同等法律效益。</p>

      <p>
        二、“食堂有饭”有权根据需要补充、修改本须知内容或各项规则，并在官网或公众号、小程序上以适当形式告知用户。任何补充和修改一经公布，用户未在7日内提出书面意见，则新规则即时生效并成为本须知的一部分。若用户继续使用，则视为同意并愿意遵守。除另有说明外，任何服务及功能的修改均适用本须知。
      </p>

      <p>三、用户使用“食堂有饭”，须向“食堂有饭”提交真实、合法、有效信息并根据“食堂有饭”相关收费规则支付相关费用。“食堂有饭”可在收费后向用户开具正式发票。</p>

      <p>四、除不可抗力或根据相关法律法规的要求，“食堂有饭”有义务为用户提供良好的服务，确保用户注册信息不被泄露。未经用户同意，不得出卖用户注册信息用于直接或间接牟利。</p>

      <p>五、用户及用户食堂的消费者通过“食堂有饭”小程序收支费用，应遵守微信支付相关规则并向微信支付相关费用。“食堂有饭”建议并可协助用户申请微信商户收付款资格，直接收款。</p>

      <p>六、用户获得“食堂有饭”相关版本权限后，可独立使用管理后台并按自身管理需求自由进行相关设置，用户食堂的消费者与用户的消费关系及消费纠纷，与“食堂有饭”无关。</p>

      <p style="text-align:right;">福建尚汇信息科技有限公司</p>

      <p style="text-align:right;">2020年6月24日</p>
    </a-modal>

    <!-- 发票详情 -->
    <a-modal v-model="showApplyDetail" :title="applyTitle" @ok="updateApplyFn">
      <a-form-model ref="applyForm" :model="applyDetail" :rules="applyFormRules" :label-col="{span:6}"
        :wrapper-col="{span:16}">
        <a-form-model-item v-if="applyDetail.state != 1" label="开票状态">
          {{applyDetail.state == 2 ? '已申请' : applyDetail.state == 3 ? '已开票' : applyDetail.state == 4 ? '已拒绝，请修改后重新提交' : ''}}
          <a-button v-if="applyDetail.url" @click="downloadApply">查看电子发票</a-button>
        </a-form-model-item>
        <a-form-model-item label="发票类型" prop="type">
          <a-select v-model="applyDetail.type" :disabled="applyDetail.state == 2 || applyDetail.state == 3">
            <a-select-option v-for="item in applyTypes" :key="item.value" :value="item.value">{{item.label}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="发票抬头" prop="rise">
          <a-input v-model="applyDetail.rise" placeholder="请填写个人姓名或单位全称" :disabled="applyDetail.state == 2 || applyDetail.state == 3" />
        </a-form-model-item>
        <a-form-model-item label="纳税人识别号" prop="code">
          <a-input v-model="applyDetail.code" placeholder="请填写个人身份证号或单位证件号" :disabled="applyDetail.state == 2 || applyDetail.state == 3" />
        </a-form-model-item>
        <a-form-model-item label="开户行" prop="open_bank"  v-if="applyDetail.type == 3">
          <a-input v-model="applyDetail.open_bank" :disabled="applyDetail.state == 2 || applyDetail.state == 3" />
        </a-form-model-item>
        <a-form-model-item label="账号" prop="open_bank_1"  v-if="applyDetail.type == 3">
          <a-input v-model="applyDetail.open_bank_1" :disabled="applyDetail.state == 2 || applyDetail.state == 3" />
        </a-form-model-item>
        <a-form-model-item label="单位地址" prop="address" v-if="applyDetail.type == 3">
          <a-input v-model="applyDetail.address" :disabled="applyDetail.state == 2 || applyDetail.state == 3" />
        </a-form-model-item>
        <a-form-model-item label="单位电话" prop="address_1" v-if="applyDetail.type == 3">
          <a-input v-model="applyDetail.address_1" :disabled="applyDetail.state == 2 || applyDetail.state == 3" />
        </a-form-model-item>
        <a-form-model-item label="备注">
          <a-textarea v-model="applyDetail.remark" :disabled="applyDetail.state == 2 || applyDetail.state == 3" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <!-- 退款记录 -->
    <a-modal v-model="showRefundOrders" title='退款记录' @ok="showRefundOrders = false">
      <a-table :columns="refundColumns" :data-source="refund_order" rowKey="id" :pagination="false">
      </a-table>
    </a-modal>

    <!-- 购买的功能模块在当前版本是否可用的提示 -->
    <a-modal :width="600" v-model="showCanIUse" title='购买须知' @ok="knowAndContinue" okText="已了解并继续购买">
      <div v-for="(item, index) in checkedOptionsObj" :key="index">
        购买功能模块【{{item.get_option.get_sys_module.name}}-{{item.get_option.name}}】后在当前版本
        <span v-if="item.get_option.open_version.indexOf(currentVersionNew) == -1" style="color:red;font-weight:bold;">不可用</span>
        <span v-else style="color:green;font-weight:bold;">可用</span>
        <span style="float:right;" v-if="item.get_option.open_version.indexOf(currentVersionNew) == -1">该功能模块适用于
          <span style="font-weight:bold;" v-for="subItem in item.get_option.open_version" :key="subItem.id">
            {{subItem == 1 ? '普通模式' : subItem == 2 ? '精简模式' : '兼容模式'}}
          </span>
        </span>
      </div>
    </a-modal>

    <a-modal v-model="showKefu" title="请扫描客服二维码" :footer="null" :width="300">
      <div style="width: 100%;text-align: center;">
        <img style="width:250px;margin: 0 auto;" src="https://styf.oss-cn-shenzhen.aliyuncs.com/uploads/images/qrcode/shouqian.png">
        <div>请微信扫码联系客服沟通详细需求</div>
      </div>
    </a-modal>

  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import MyBreadcrumb from "@/components/common/MyBreadcrumb.vue";
import VersionTable from "@/components/common/VersionTable.vue";

import QRCode from "qrcodejs2";
import {
  getInstVersion,
  postAddInstitutionApply,
  calcInstitutionVersion,
  updateInstitutionVersion,

  //new
  showProduction,
  addShopCart,
  showShopCart,
  removeShopCart,
  generateOrder,
  payOrder,
  quickOrder,
  getOrder,
  removeOrder,
  updateApply,
  getOrderList
} from "@/request/api";
import moment from 'moment';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: { MyBreadcrumb, VersionTable },
  data () {
    return {
      breadcrumbs: [
        {
          key: 0,
          title: "单位管理",
        },
        {
          key: 1,
          title: "版本信息",
        },
      ],
      versionInfo: {},
      showRule: false,
      /*
       *@description:new
       *@author: zhangchenhui
       *@date: 2021-01-25 17:02:30
      */
      isUpdatePage: false,
      showUpDownV: true,
      warm: '',
      versions: [],
      version: 1,
      durations: [],
      duration: 0,
      readCheck: false,
      order_number: '',//订单号

      updateVPrice: 0,//升级/续费版本时选择后的总价
      expandTotalPrice: 0,//增值功能选择后的总价
      hardwaresPrice: 0,//硬件商品选择后的总价

      checkedModules: [],//已勾选的模块数组
      addedHardWares: [],//已勾选(欲购买)的硬件商品数组

      tableData1: [], //意向购买列表
      loading1: false, //table加载状态
      selectedRowKeys: [],//意向购买table多选数组
      columns1: [
        {
          title: "产品图片",
          key: "img",
          scopedSlots: { customRender: "img" }
        },
        {
          title: "产品名称",
          key: "name",
          scopedSlots: { customRender: "name" }
        },
        {
          title: "单价(元)",
          key: "price",
          scopedSlots: { customRender: "price" }
        },
        {
          title: "数量",
          key: "num",
          scopedSlots: { customRender: "num" }
        },
        {
          title: "总价(元)",
          key: "total",
          scopedSlots: { customRender: "total" }
        },
        {
          title: "到期时间",
          key: "due_date",
          scopedSlots: { customRender: "due_date" }
        },
        {
          title: "操作",
          key: "action",
          scopedSlots: { customRender: "action" },
        },
      ],

      status: 0,//0全部1待支付2已支付
      statuses: [
        {
          key: 0,
          value: '全部'
        },
        {
          key: 1,
          value: '待支付订单'
        },
        {
          key: 2,
          value: '已支付订单'
        }
      ],
      tableData2: [], //从接口请求回来并在js部分整理好的表格数据
      loading2: false, //table加载状态
      columns2: [
        {
          title: "订单号",
          key: "order_number",
          dataIndex: "order_number",
        },
        {
          title: "下单时间",
          key: "created_at",
          dataIndex: "created_at",
        },
        {
          title: "预支付总价",
          key: "advance",
          dataIndex: "advance",
        },
        {
          title: "实际支付总价",
          key: "payment",
          dataIndex: "payment",
        },
        {
          title: "状态",
          key: "status",
          scopedSlots: { customRender: "status" },
        },
        {
          title: "支付方式",
          key: "payment_mode",
          scopedSlots: { customRender: "payment_mode" },
        },
        {
          title: "支付时间",
          key: "payment_at",
          scopedSlots: { customRender: "payment_at" },
        },
        {
          title: "操作",
          key: "action",
          scopedSlots: { customRender: "action" },
        },
      ],
      innerColumns: [
        { title: '产品名称', dataIndex: 'production_title', key: 'production_title' },
        { title: '产品单价', dataIndex: 'production_price', key: 'production_price' },
        { title: '数量', key: 'buy_num', scopedSlots: { customRender: "buy_num" } },
        { title: '产品预支付总价', dataIndex: 'production_advance', key: 'production_advance' },
        { title: '产品实际支付总价', dataIndex: 'production_payment', key: 'production_payment' },
        { title: "到期时间", key: "production_expire", scopedSlots: { customRender: "production_expire" }, },
        { title: '备注', dataIndex: 'buy_remark', key: 'buy_remark' },
      ],
      //页配置
      pagination2: {
        total: 0,
        pageSize: 20,
        current: 1,
      },

      // 增值功能数组
      expandModules: [],

      // 硬件数组
      hardwares: [],

      selectPayType: false,
      showWxScan: false,
      payTypes: [
        {
          key: 1,
          value: '微信'
        },
        {
          key: 2,
          value: '支付宝'
        },
        // {
        //   key: 3,
        //   value: '企业网银'
        // },
        {
          key: 4,
          value: '对公汇款'
        },
        // {
        //   key: 6,
        //   value: '快捷支付'
        // }
      ],
      payType: '',
      reLogin: false,//购买完是否需要重新登陆
      showUnderLine: false,

      //发票相关
      showApplyDetail: false,
      applyTitle: '开具发票',
      mainOrderid: '',
      applyFormRules: {
        rise: [{ required: true, message: '请填写发票抬头', trigger: 'blur' }],
        type: [{ required: true, message: '请选择发票类型', trigger: 'change' }],
        code: [{ required: true, message: '请填写纳税人识别号', trigger: 'blur' }],
        mobile: [{ required: false, message: '请输入正确的手机号码', pattern: /^1[0-9]{10}$/, trigger: 'change' }],
        open_bank: [{ required: true, message: '请填写开户行', trigger: 'blur' }],
        open_bank_1: [{ required: true, message: '请填写账号', trigger: 'blur' }],
        address: [{ required: true, message: '请填写地址', trigger: 'blur' }],
        address_1: [{ required: true, message: '请填写电话', trigger: 'blur' }],
      },
      applyTypes: [
        {
          value: 1,
          label: '普通发票(电子版)'
        },
        {
          value: 3,
          label: '增值税专用发票(电子版)'
        }
      ],
      applyDetail: {//发票详情
        rise: '',
        type: 1,
        code: '',
        open_bank: '',
        open_bank_1: '',
        address: '',
        address_1: '',
        state: 1,
        url: '',
        remark:''
      },

      //退款记录
      showRefundOrders: false,
      refund_order: [],
      refundColumns: [
        { title: '产品名称', dataIndex: 'production_title', key: 'production_title' },
        { title: '退款数量', dataIndex: 'refund_num', key: 'refund_num' },
        { title: '退款价格', dataIndex: 'refund_price', key: 'refund_price' },
        { title: '备注', dataIndex: 'refund_remark', key: 'refund_remark' },
      ],
      query:{},
      defaultActiveKey: JSON.parse(localStorage.getItem('agent_inst')) ? '2' : '1',
      options: [],//功能模块数组
      optionTotalPrice: 0,//功能模块选择后的总价
      checkedOptions: [],//已勾选的功能模块id数组
      checkedOptionsObj: [],//已勾选的功能模块对象数组
      agent_inst: JSON.parse(localStorage.getItem('agent_inst')),
      currentVersionNew: JSON.parse(localStorage.getItem('inst')).versionID.toString(),
      showCanIUse: false,
      showHistory:false,//查看历史订单
      showKefu:false,
      kefuSrc:'https://styf.oss-cn-shenzhen.aliyuncs.com/uploads/images/qrcode/lanjie.png'
    };
  },
  //监听属性 类似于data概念 
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    moment,
    showKeFuCode(){
      this.showKefu = true;
    },
    setKefuSrc(){
      if(localStorage.getItem("kefuSrc") != null){
        this.kefuSrc = localStorage.getItem("kefuSrc");
      }
    },
    /*
     *@functionName: 获取各版本列表
     *@description:
     *@author: zhangchenhui
     *@date: 2021-02-04 10:12:05
    */
    async showVersionFn () {
      let param = {
        type: 1 //1.版本2.增值功能3.硬件
      }
      try {
        let { data } = await showProduction(param)
        if (data.msg == '获取成功') {
          this.versions = data.data.map(item => {
            item.price = item.price * 1
            return item
          })
          this.durations = data.data[0].list
          this.version = data.data[0].id
          this.duration = data.data[0].list.length != 0 ? data.data[0].list[0].period : 0
          this.updateVPrice = data.data[0].list.length != 0 ? data.data[0].list[0].current_price : 0
          if (data.data[0].warm) {
            this.warm = data.data[0].warm
          } else {
            this.warm = ''
          }
        } else if (data.msg == '本食堂版本无需变更') {
          this.showUpDownV = false
        }
      } catch (err) {
        let { errors } = err.data;
        for (let i in errors) {
          this.$message.error(errors[i][0]);
        }
      }
    },

    /*
     *@functionName: 获取增值功能列表
     *@description:
     *@author: zhangchenhui
     *@date: 2021-02-05 10:07:20
    */
    async showExpands () {
      let param = {
        type: 2
      }
      try {
        let { data } = await showProduction(param)
        if (data.msg == '获取成功') {
          this.expandModules = data.data.map(item => {
            item.price = item.price * 1
            return item
          })
        }
      } catch (err) {
        let { errors } = err.data;
        for (let i in errors) {
          this.$message.error(errors[i][0]);
        }
      }
    },

    /*
     *@functionName: 获取硬件商品
     *@description:
     *@author: zhangchenhui
     *@date: 2021-02-05 10:16:30
    */
    async showGoods () {
      let param = {
        type: 3
      }
      try {
        let { data } = await showProduction(param)
        if (data.msg == '获取成功') {
          this.hardwares = data.data.map(item => {
            item.price = item.price * 1
            item.wanna = 0
            item.totalPrice = 0
            return item
          })
          this.hardwaresPrice = 0
        }
      } catch (err) {
        let { errors } = err.data;
        for (let i in errors) {
          this.$message.error(errors[i][0]);
        }
      }
    },

    /*
     *@functionName: 购买硬件-单品数量减
     *@description:
     *@author: zhangchenhui
     *@date: 2021-02-05 10:55:59
    */
    async reduceWanna (id) {
      await this.hardwares.map(item => {
        if (item.id == id) {
          item.wanna = item.wanna - 1
          item.totalPrice = new this.$BigNumber(item.wanna).times(item.price)

        }
      })
      await this.getHardwaresPrice()
    },

    /*
      *@functionName: 购买硬件-单品数量增
      *@description:
      *@author: zhangchenhui
      *@date: 2021-02-05 10:55:59
     */
    async addWanna (id) {
      await this.hardwares.map(item => {
        if (item.id == id) {
          item.wanna = item.wanna + 1
          item.totalPrice = new this.$BigNumber(item.wanna).times(item.price)
        }
      })
      await this.getHardwaresPrice()
    },

    /*
     *@functionName: 计算硬件商品合计价格
     *@description:
     *@author: zhangchenhui
     *@date: 2021-02-06 10:41:03
    */
    getHardwaresPrice () {
      let total = 0
      this.hardwares.map(item => {
        total = new this.$BigNumber(total).plus(item.totalPrice).toNumber()
      })
      this.hardwaresPrice = total
    },

    /*
     *@functionName: 获取意向购买列表
     *@description:
     *@author: zhangchenhui
     *@date: 2021-02-05 17:51:54
    */
    async showShopCartFn () {
      this.loading1 = true
      try {
        let { data } = await showShopCart()
        if (data.msg == '获取成功') {
          this.tableData1 = data.data
          this.loading1 = false
        }
      } catch (err) {
        let { errors } = err.data;
        for (let i in errors) {
          this.$message.error(errors[i][0]);
        }
      }
    },

    /*
     *@functionName: 移除意向购买项
     *@description:
     *@author: zhangchenhui
     *@date: 2021-02-06 09:47:14
    */
    removeWanna (r) {
      this.$confirm({
        title: `确定从意向购买列表中移除产品${r.production.title}？`,
        onOk: async () => {
          let cart = r.id;
          try {
            let { data } = await removeShopCart(cart)
            if (data.msg == '移除成功') {
              this.$message.success(data.msg)
              this.showShopCartFn()
            }
          } catch (err) {
            let { errors } = err.data;
            for (let i in errors) {
              this.$message.error(errors[i][0]);
            }
          }

        },
      })
    },

    /*
     *@functionName: 打开意向购买/订单与发票管理
     *@description:
     *@author: zhangchenhui
     *@date: 2021-02-06 09:50:31
    */
    showWannaTable () {
      this.isUpdatePage = !this.isUpdatePage
      this.showShopCartFn()
      this.getOrderFn(this.status)
    },

    /*
     *@functionName: 获取订单列表
     *@description:
     *@author: zhangchenhui
     *@date: 2021-02-06 20:45:24
    */
    async getOrderFn (status) {
      this.loading2 = true
      if (this.showHistory) {
          try {
            let { data } = await getOrder({ status })
            this.tableData2 = data.data
            //处理页码
            const pagination = { ...this.pagination };
            pagination.total = data.total;
            this.pagination = pagination;
            this.loading2 = false
          } catch (err) {
            let { errors } = err.data;
            for (let i in errors) {
              this.$message.error(errors[i][0]);
            }
          }
      } else{
        try {
            let { data } = await getOrderList({ status })
            this.tableData2 = data.data
            console.log(data.data)
            //处理页码
            const pagination = { ...this.pagination };
            pagination.total = data.total;
            this.pagination = pagination;
            this.loading2 = false
          } catch (err) {
            let { errors } = err.data;
            for (let i in errors) {
              this.$message.error(errors[i][0]);
            }
          }
      }
      
    },

    /*
     *@functionName: 分页
     *@description:
     *@author: zhangchenhui
     *@date: 2021-02-06 21:05:43
    */
    handleTableChange (pagination) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.queryParam.page = pagination.current;
      this.getOrderFn(this.status);
    },

    /*
     *@functionName: 根据订单状态查询订单列表
     *@description:
     *@author: zhangchenhui
     *@date: 2021-02-06 20:52:06
    */
    searchOrders (v) {
      this.status = v
      this.getOrderFn(v)
    },

    /*
     *@functionName: 移除待支付订单
     *@description:
     *@author: zhangchenhui
     *@date: 2021-02-07 09:40:13
    */
    async removeOrderFn (r) {
      this.$confirm({
        title: `确定移除订单号为${r.order_number}的订单?`,
        onOk: async () => {
          let id = r.id
          try {
            let { data } = await removeOrder(id)
            this.$message.success(data.msg)
            this.getOrderFn(this.status)
          } catch (err) {
            let { errors } = err.data;
            for (let i in errors) {
              this.$message.error(errors[i][0]);
            }
          }
        }
      })
    },

    /*
     *@functionName: 订单列表-支付
     *@description:
     *@author: zhangchenhui
     *@date: 2021-02-07 15:37:21
    */
    async payFromOrders (r) {
      this.order_number = r.order_number;
      this.payType = ''
      this.selectPayType = true
    },

    /*
     *@functionName: 立即购买（三个tab共用）
     *@description:1.版本2.模块3.硬件
     *@author: zhangchenhui
     *@date: 2021-02-07 11:38:11
    */
    async payRightNow (type) {

      //版本
      if (type == 1) {
        let param = [], obj = {
          id: this.version,
          num: this.duration
        }
        param.push(obj)
        // 判断是否仅降级
        if (this.updateVPrice == 0) {
          //降级
          this.$confirm({
            content: '确定仅降级版本？',
            onOk: async () => {

              try {
                let { data } = await quickOrder({
                  data: param,
                  total: this.updateVPrice
                })
                this.order_number = data.order_number
                // console.log('先生成订单')

                try {
                  let mode = 5;//仅降级时mode = 5
                  let { data } = await payOrder({
                    mode,
                    order_number: this.order_number
                  })
                  // console.log('再根据订单降级')
                  this.$message.success('降级成功,请重新登陆')
                  this.$store.commit("REMOVE_COUNT");

                } catch (err) {
                  let { errors } = err.data;
                  for (let i in errors) {
                    this.$message.error(errors[i][0]);
                  }
                }

              } catch (err) {
                let { errors } = err.data;
                for (let i in errors) {
                  this.$message.error(errors[i][0]);
                }
              }
            }
          })
        } else {
          try {
            let { data } = await quickOrder({
              data: param,
              total: this.updateVPrice
            })
            this.order_number = data.order_number
            this.$message.success('创建订单成功,请选择支付方式')
            this.payType = ''
            this.selectPayType = true
            this.reLogin = true
          } catch (err) {
            let { errors } = err.data;
            for (let i in errors) {
              this.$message.error(errors[i][0]);
            }
          }
        }
      }

      //模块
      if (type == 2) {
        if (this.checkedModules.length == 0) {
          this.$message.info('请先勾选要添加的增值功能')
          return
        }
        if (this.versionInfo.version != 1 && this.versionInfo.version != 2) {
          this.$message.info('当前版本不能购买增值功能，请先升级至商业版')
          return
        }
        let param = []
        this.checkedModules.map(id => {
          param.push({
            id,
            num: 1
          })
        })
        try {
          let { data } = await quickOrder({
            data: param,
            total: this.expandTotalPrice
          })
          this.$message.success('创建订单成功,请选择支付方式')
          this.order_number = data.order_number
          this.payType = ''
          this.selectPayType = true
          this.reLogin = true
        } catch (err) {
          let { errors } = err.data;
          for (let i in errors) {
            this.$message.error(errors[i][0]);
          }
        }
      }

      //硬件
      if (type == 3) {
        if (this.hardwaresPrice == 0) {
          this.$message.info('请先选择硬件商品')
          return
        }
        let param = []
        await this.hardwares.map(item => {
          if (item.wanna > 0) {
            param.push({
              id: item.id,
              num: item.wanna
            })
          }
        })
        try {
          let { data } = await quickOrder({
            data: param,
            total: this.hardwaresPrice
          })
          this.$message.success('创建订单成功,请选择支付方式')
          this.order_number = data.order_number
          this.payType = ''
          this.selectPayType = true
          this.reLogin = false
        } catch (err) {
          let { errors } = err.data;
          for (let i in errors) {
            this.$message.error(errors[i][0]);
          }
        }
      }

      //功能模块
      if (type == 4) {
        if (this.checkedOptions.length == 0) {
          this.$message.info('请先勾选要添加的功能模块')
          return
        }
        // if (this.versionInfo.version != 1 && this.versionInfo.version != 2) {
        //   this.$message.info('当前版本不能购买增值功能，请先升级至商业版')
        //   return
        // }
        this.showCanIUse = true
      }
    },

    async knowAndContinue(){
      let zeroOptions = this.checkedOptionsObj.filter(value => {
        return value.price == 0
      });
      // console.log('已选择的零元功能模块数组')
      // console.log(zeroOptions)
      if(this.checkedOptions.length == 1 && zeroOptions.length == 1){
        // 只勾选了一个功能模块且该功能模块为零元
        if(zeroOptions[0].get_option.open_version.indexOf(this.currentVersionNew) != -1){
          // 该零元功能模块适用于当前版本
          // 跳转功能模块列表引导用户对零元功能模块进行购买
          this.$router.push({
            path: "/ModuleDetail",
            query: {
              id: zeroOptions[0].get_option.module_id,
              version: this.currentVersionNew * 1
            },
          });
        }else{
          this.$message.error('该零元功能模块不适用于当前版本')
          return
        }
      }else{
        let param = [];
        this.checkedOptions.map(id => {
          param.push({
            id,
            num: 1
          })
        })
        try {
          let { data } = await quickOrder({
            data: param,
            total: this.optionTotalPrice
          })
          this.$message.success('创建订单成功,请选择支付方式')
          this.order_number = data.order_number
          this.payType = ''
          this.selectPayType = true
          this.reLogin = false
        } catch (err) {
          let { errors } = err.data;
          for (let i in errors) {
            this.$message.error(errors[i][0]);
          }
        }
      }
    },

    /*
     *@functionName: 添加到意向购买（三个tab共用）
     *@description:type:1.版本2.模块3.硬件
     *@author: zhangchenhui
     *@date: 2021-02-06 10:59:38
    */
    async addToWannas (type) {
      if (type == 1) {
        this.$confirm({
          title: '确定添加到意向购买?',
          content: '添加成功后可到意向购买列表中查看',
          onOk: async () => {
            let obj = {
              id: this.version,
              num: this.duration
            }, param = []
            param.push(obj)
            try {
              let { data } = await addShopCart({ data: param })
              this.$message.success(data.msg)
              this.showShopCartFn();
            } catch (err) {
              let { errors } = err.data;
              for (let i in errors) {
                this.$message.error(errors[i][0]);
              }
            }
          }
        })
      }

      if (type == 2) {
        if (this.checkedModules.length == 0) {
          this.$message.info('请先勾选要添加的增值功能')
          return
        }
        let goOn = false;
        await this.tableData1.map(item => {
          if (item.production.type == 1) {
            goOn = true
          }
        })
        if (!goOn && this.versionInfo.version != 1 && this.versionInfo.version != 2) {
          this.$message.info('当前版本不能直接添加增值功能，请与商业版一起添加购买')
          return
        }
        this.$confirm({
          title: '确定添加到意向购买?',
          content: '添加成功后可到意向购买列表中查看',
          onOk: async () => {
            let param = []
            this.checkedModules.map(id => {
              param.push({
                id,
                num: 1
              })
            })
            try {
              let { data } = await addShopCart({ data: param })
              if (data.msg == '添加成功') {
                this.$message.success(data.msg)
              }
            } catch (err) {
              let { errors } = err.data;
              for (let i in errors) {
                this.$message.error(errors[i][0]);
              }
            }
          }
        })
      }

      if (type == 3) {
        if (this.hardwaresPrice == 0) {
          this.$message.info('请先选择硬件商品')
          return
        }
        this.$confirm({
          title: '确定添加到意向购买?',
          content: '添加成功后可到意向购买列表中查看',
          onOk: async () => {
            let param = []
            await this.hardwares.map(item => {
              if (item.wanna > 0) {
                param.push({
                  id: item.id,
                  num: item.wanna
                })
              }
            })
            try {
              let { data } = await addShopCart({ data: param })
              if (data.msg == '添加成功') {
                this.$message.success(data.msg)
              }
            } catch (err) {
              let { errors } = err.data;
              for (let i in errors) {
                this.$message.error(errors[i][0]);
              }
            }
          }
        })
      }

      if (type == 4) {
        if (this.checkedOptions.length == 0) {
          this.$message.info('请先勾选要添加的选项')
          return
        }
        // let goOn = false;
        // await this.tableData1.map(item => {
        //   if (item.production.type == 1) {
        //     goOn = true
        //   }
        // })
        // if (!goOn && this.versionInfo.version != 1 && this.versionInfo.version != 2) {
        //   this.$message.info('当前版本不能直接添加增值功能，请与商业版一起添加购买')
        //   return
        // }
        this.$confirm({
          title: '确定添加到意向购买?',
          content: '添加成功后可到意向购买列表中查看',
          onOk: async () => {
            let param = []
            this.checkedOptions.map(id => {
              param.push({
                id,
                num: 1
              })
            })
            try {
              let { data } = await addShopCart({ data: param })
              if (data.msg == '添加成功') {
                this.$message.success(data.msg)
              }
            } catch (err) {
              let { errors } = err.data;
              for (let i in errors) {
                this.$message.error(errors[i][0]);
              }
            }
          }
        })
      }
    },

    /*
     *@functionName: 意向购买列表多选方法
     *@description:
     *@author: zhangchenhui
     *@date: 2021-02-06 16:03:48
    */
    onSelectChange (selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },

    /*
     *@functionName: 意向购买-购买已勾选项
     *@description:
     *@author: zhangchenhui
     *@date: 2021-02-06 16:01:05
    */
    async buyChecked () {
      let arr = [], goOn = false;
      await this.selectedRowKeys.map(id => {
        let index = this.findIndex(id, this.tableData1)
        if (index != -1) {
          arr.push(this.tableData1[index])
        }
      })
      await arr.map(item => {
        if (item.production.type == 1) {
          goOn = true;
        }
      })
      if (!goOn && this.versionInfo.version != 1 && this.versionInfo.version != 2) {
        this.$message.info('当前版本不能只购买增值功能，请与商业版一起购买')
        return
      }

      let total = 0
      await this.selectedRowKeys.map(id => {
        let index = this.findIndex(id, this.tableData1)
        if (index != -1) {
          total = total + this.tableData1[index].production.current_price
        }
      })
      this.$info({
        content: total == 0 ? '即将降级版本' : '即将生成订单，可在订单与发票管理中查看订单',
        okText: '知道了',
        onOk: async () => {


          try {
            let { data, msg } = await generateOrder({
              ids: this.selectedRowKeys,
              total
            })

            this.order_number = data.order_number

            if (total == 0) {
              //当只勾选了降级版本（0元）
              try {
                let mode = 5;//仅降级时mode = 5
                let { data } = await payOrder({
                  mode,
                  order_number: this.order_number
                })
                this.$message.success('降级成功,请重新登陆')
                this.$store.commit("REMOVE_COUNT");

              } catch (err) {
                let { errors } = err.data;
                for (let i in errors) {
                  this.$message.error(errors[i][0]);
                }
              }
            } else {
              this.$message.success(data.msg)
              this.selectedRowKeys = []
              this.showShopCartFn()
              this.payType = ''
              this.selectPayType = true
            }


          } catch (err) {
            if (err.data.msg) {
              this.$message.error(err.data.msg)
            } else {
              let { errors } = err.data;
              for (let i in errors) {
                this.$message.error(errors[i][0]);
              }
            }
          }
        }
      })

    },

    /*
     *@functionName: 选择支付方式
     *@description:
     *@author: zhangchenhui
     *@date: 2021-02-06 16:26:46
    */
    async changePayType (e) {
      let mode = e.target.value
      try {
        let { data } = await payOrder({
          mode,
          order_number: this.order_number
        })
        if (mode == 1) {
          // 微信
          this.showWxScan = true;
          this.$refs.qrCodeUrl.innerHTML = ""; //先清除二维码
          var qrcode = new QRCode(this.$refs.qrCodeUrl, {
            text: data.url,
            width: 150,
            height: 150,
            colorDark: "#000000",
            colorLight: "#ffffff",
            correctLevel: QRCode.CorrectLevel.H,
          });
        }
        if (mode == 2) {
          //支付宝
          document.getElementById("aliPay").innerHTML = data;
          const div = document.createElement("div"); // 创建div
          div.innerHTML = data; // 将返回的form 放入div
          document.getElementById("aliPay").appendChild(div);
          document.forms[0].submit();
        }
        if (mode == 3) {
          //企业网银
          document.getElementById("unionPay").innerHTML = data;
          const div = document.createElement("div"); // 创建div
          div.innerHTML = data; // 将返回的form 放入div
          document.getElementById("unionPay").appendChild(div);
          document.forms[0].submit();
        }
        if (mode == 4) {
          //线下
          this.showUnderLine = true
          this.selectPayType = false
        }
        if (mode == 6) {
          //快捷支付

          document.getElementById("quickPay").innerHTML = data;
          const div = document.createElement("div"); // 创建div
          div.innerHTML = data; // 将返回的form 放入div
          document.getElementById("quickPay").appendChild(div);
          document.forms[0].submit();
        }
      } catch (err) {
        let { errors } = err.data;
        for (let i in errors) {
          this.$message.error(errors[i][0]);
        }
      }
    },

    // 更新食堂信息，权限数组
    async updateAuths() {
      await this.$store.dispatch("GET_INST_INFO");
      await this.$store.dispatch("GET_ME");
      await this.$store.dispatch("GET_ALL_AUTH");
    },

    /*
     *@functionName: 微信支付成功
     *@description:
     *@author: zhangchenhui
     *@date: 2021-02-07 10:12:57
    */
    async wxPaySuccess () {
      if(this.defaultActiveKey == '4'){
        await this.updateAuths()
      }
      this.payType = ''
      if (this.$refs.qrCodeUrl) {
        this.$refs.qrCodeUrl.innerHTML = ""; //清除微信付款二维码
      }
      this.showWxScan = false;
      this.selectPayType = false
      this.showShopCartFn();
    },

    /*
     *@functionName: 选择支付方式-取消
     *@description:
     *@author: zhangchenhui
     *@date: 2021-02-06 16:36:03
    */
    noSurePayType () {
      this.$message.info('当前订单已创建,您可以到订单与发票管理中进行管理')
      this.payType = ''
      if (this.$refs.qrCodeUrl) {
        this.$refs.qrCodeUrl.innerHTML = ""; //清除微信付款二维码
      }
      this.showWxScan = false;
      this.selectPayType = false
    },

    /*
     *@functionName: 获取当前版本信息
     *@description:
     *@author: zhangchenhui
     *@date: 2021-02-06 16:00:35
    */
    getInstVersionFn () {
      getInstVersion().then((res) => {
        this.versionInfo = res.data;
      });
    },

    /*
     *@functionName: 选择版本
     *@description:
     *@author: zhangchenhui
     *@date: 2021-02-05 17:25:48
    */
    chooseVersion (e) {
      let checked = e.target.value;
      this.versions.map(item => {
        if (item.id == checked) {
          if (item.list.length != 0) {
            this.durations = item.list
            this.duration = item.list[0].period
            this.updateVPrice = item.list[0].current_price
            this.warm = ''
          } else {
            this.updateVPrice = 0
            this.warm = item.warm
          }
        }
      })
    },

    /*
     *@functionName: 选择要购买的时长
     *@description:
     *@author: zhangchenhui
     *@date: 2021-02-05 17:25:59
    */
    chooseDuration (e) {
      let checked = e.target.value;
      this.durations.map(item => {
        if (item.period == checked) {
          this.updateVPrice = item.current_price
        }
      })

    },

    /*
     *@functionName: 已阅读并勾选协议
     *@description:
     *@author: zhangchenhui
     *@date: 2021-02-05 17:26:50
    */
    changeCheck (e) {
      this.readCheck = e.target.checked;
    },

    /*
     *@functionName: 获取功能模块列表
     *@description:
     *@author: zhangchenhui
     *@date: 2021-02-05 10:07:20
    */
    async showOptions () {
      let param = {
        type: 4
      }
      try {
        let { data } = await showProduction(param)
        if (data.msg == '获取成功') {
          this.options = data.data.map(item => {
            item.price = item.price * 1
            return item
          })
          // console.log('功能模块列表')
          // console.log(this.options)
        }
      } catch (err) {
        let { errors } = err.data;
        for (let i in errors) {
          this.$message.error(errors[i][0]);
        }
      }
    },

    /*
     *@functionName: 切换版本/模块/硬件tab
     *@description:
     *@author: zhangchenhui
     *@date: 2021-02-05 17:26:56
    */
    changeTab1 (tab) {
      if(tab == 1){
        //系统版本
        this.showVersionFn()
      }
      if (tab == 2) {
        //增值功能
        this.showExpands()
      }
      if (tab == 3) {
        //硬件商品
        this.showGoods()
      }
      if(tab == 4){
        this.showOptions()
      }
    },

    /*
     *@functionName: 切换记录/订单列表tab
     *@description:tab:1.意向购买2.订单列表
     *@author: zhangchenhui
     *@date: 2021-02-05 17:27:01
    */
    changeTab2 (tab) {
      if (tab == 1) {
        this.showShopCartFn()
      } else {
        this.getOrderFn(this.status)
      }
    },

    /*
     *@functionName: 选择增值功能
     *@description:
     *@author: zhangchenhui
     *@date: 2021-02-05 17:27:06
    */
    changeModule (checkedValues) {
      let total = 0
      checkedValues.map(id => {
        let index = this.findIndex(id, this.expandModules)
        if (index != -1) {
          total = new this.$BigNumber(total).plus(this.expandModules[index].price).toNumber()
        }
      })
      this.expandTotalPrice = total
      this.checkedModules = checkedValues
    },

    /*
     *@functionName: 选择功能模块
     *@description:
     *@author: zhangchenhui
     *@date: 2021-02-05 17:27:06
    */
    changeOption (checkedValues) {
      let total = 0, checkedOptionsObj = [];
      checkedValues.map(id => {
        let index = this.findIndex(id, this.options)
        if (index != -1) {
          total = new this.$BigNumber(total).plus(this.options[index].price).toNumber()
          checkedOptionsObj.push(this.options[index])
        }
      })
      this.optionTotalPrice = total
      this.checkedOptions = checkedValues
      this.checkedOptionsObj = checkedOptionsObj
      // console.log(this.checkedOptions)
      // console.log(this.checkedOptionsObj)
    },

    /*
     *@functionName: 根据id对比
     *@description:
     *@author: zhangchenhui
     *@date: 2021-02-06 10:25:35
    */
    findIndex (id, data) {
      let find = data.findIndex((item) => {
        return item.id == id
      })
      return find
    },

    //打开发票详情模态框
    showApplyDetailFn (r) {
      this.mainOrderid = r.id
      if (r.get_invoice == null || (r.get_invoice != null && r.get_invoice.state == 4)) {
        let created_at = r.created_at,
          allow_date = moment().subtract(1, 'year').format("YYYY-MM-DD HH:mm:ss");
        // console.log('下单时间', created_at)
        // console.log('允许开发票时间（一年内）', allow_date)
        if (moment(created_at).isBefore(allow_date)) {
          //超过一年的订单不支持开发票，引导联系客服
          this.$message.info('超过1年的订单不支持开票,请联系客服')
          return
        }
        this.applyTitle = '开具发票'
        if(r.get_invoice !=null){
          console.log('open_bank',r.get_invoice.open_bank)
          if (r.get_invoice.open_bank && typeof r.get_invoice.open_bank === 'string') {
            r.get_invoice.open_bank = r.get_invoice.open_bank.split(",");
          }else {
            r.get_invoice.open_bank = [];
          }
          if (r.get_invoice.address && typeof r.get_invoice.address === 'string') {
            r.get_invoice.address = r.get_invoice.address.split(",");
          }else {
            r.get_invoice.address = [];
          }
        }

        this.applyDetail = {
          rise: r.get_invoice == null ? '' : r.get_invoice.rise,
          type: r.get_invoice == null ? 1 : r.get_invoice.type,
          code: r.get_invoice == null ? '' : r.get_invoice.code,
          // open_bank: r.get_invoice == null ? '' : r.get_invoice.open_bank,
          // address: r.get_invoice == null ? '' : r.get_invoice.address,

          open_bank: r.get_invoice ==null?'':(r.get_invoice.open_bank[0]==undefined ? '' : r.get_invoice.open_bank[0]),
          open_bank_1: r.get_invoice ==null?'':(r.get_invoice.open_bank[1]==undefined ? '' : r.get_invoice.open_bank[1]),
          address: r.get_invoice ==null?'':(r.get_invoice.address[0]==undefined ? '' : r.get_invoice.address[0]),
          address_1: r.get_invoice ==null?'':(r.get_invoice.address[1]==undefined ? '' : r.get_invoice.address[1]),

          state: r.get_invoice == null ? 1 : r.get_invoice.state,
          url: r.get_invoice == null ? '' : r.get_invoice.url,
          remark: r.get_invoice == null ? '' : r.get_invoice.remark,
        }
      } else if ((r.get_invoice != null && r.get_invoice.state == 2) || (r.get_invoice != null && r.get_invoice.state == 3)) {
        this.applyTitle = '发票详情1'
        // this.applyDetail = r.get_invoice

        if(r.get_invoice !=null){
          console.log('open_bank2',r.get_invoice.open_bank)
          if (r.get_invoice.open_bank && typeof r.get_invoice.open_bank === 'string') {
            r.get_invoice.open_bank = r.get_invoice.open_bank.split(",");
          }else {
            r.get_invoice.open_bank = [];
          }
          if (r.get_invoice.address && typeof r.get_invoice.address === 'string') {
            r.get_invoice.address = r.get_invoice.address.split(",");
          }else {
            r.get_invoice.address = [];
          }
        }
        this.applyDetail = {
          rise: r.get_invoice == null ? '' : r.get_invoice.rise,
          type: r.get_invoice == null ? 1 : r.get_invoice.type,
          code: r.get_invoice == null ? '' : r.get_invoice.code,
          // open_bank: r.get_invoice == null ? '' : r.get_invoice.open_bank,
          // address: r.get_invoice == null ? '' : r.get_invoice.address,

          open_bank: r.get_invoice.open_bank[0]==undefined ? '' : r.get_invoice.open_bank[0],
          open_bank_1: r.get_invoice.open_bank[1]==undefined ? '' : r.get_invoice.open_bank[1],
          address: r.get_invoice.address[0]==undefined ? '' : r.get_invoice.address[0],
          address_1: r.get_invoice.address[1]==undefined ? '' : r.get_invoice.address[1],
          
          state: r.get_invoice == null ? 1 : r.get_invoice.state,
          url: r.get_invoice == null ? '' : r.get_invoice.url,
          remark: r.get_invoice == null ? '' : r.get_invoice.remark,
        }
      }
      this.showApplyDetail = true
    },

    //确定开具发票
    updateApplyFn () {
      if (this.applyDetail.state == 1 || this.applyDetail.state == 4) {
        this.$refs.applyForm.validate(valid => {
          if (valid) {
            let param = {
              rise: this.applyDetail.rise,
              type: this.applyDetail.type,
              code: this.applyDetail.code,
              open_bank: this.applyDetail.open_bank+','+this.applyDetail.open_bank_1,
              address: this.applyDetail.address+','+this.applyDetail.address_1,
              remark:this.applyDetail.remark
            }
            updateApply(this.mainOrderid, param).then(res => {
              this.$message.success('提交成功，等待审核')
              this.showApplyDetail = false
              this.getOrderFn(this.status)
            }).catch(err => {
              let { errors } = err.data;
              for (let i in errors) {
                this.$message.error(errors[i][0]);
              }
            })
          } else {
            return false
          }
        })
      } else {
        this.showApplyDetail = false
      }

    },

    //查看电子发票
    downloadApply () {
      window.open(this.applyDetail.url)
    },

    //展示退款记录
    showRefundFn (refund_order) {
      this.refund_order = refund_order
      this.showRefundOrders = true
    },

    goGoods(){
      this.isUpdatePage = !this.isUpdatePage
      this.showVersionFn();
    },
    goBack(){
      this.$router.push({
        path: "/VersionInformation",
      });
      this.$store.commit('UPDATE_SELECTEDKEYS',['/VersionInformation'])
    },
    showHistoryFn(){
      this.showHistory = !this.showHistory;
      this.getOrderFn(this.status);
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  async created () {
    this.query = this.$route.query;
    // console.log(this.query)
    if(this.query.id){
      // 如果有参数id传进来此页，
      // 则确认它是从模块管理页跳转过来的，
      // 那么这时候要将tab页默认激活页设置为功能模块页
      this.defaultActiveKey = '4'
      await this.showOptions()
      this.options.forEach(item => {
        // console.log('购买项的optionid')
        // console.log(item.get_option.id)
        // console.log('查询的id')
        // console.log(this.query.id)

        if(item.get_option.id == this.query.id){
          this.checkedOptions = [item.id]  // 并勾选跳转前点击购买的功能模块
          this.checkedOptionsObj = [item]
          this.optionTotalPrice = item.price
        }
      })
    }else{
      this.showVersionFn();
    }
    this.getInstVersionFn();
    // this.showShopCartFn();
    this.searchOrders();
    this.setKefuSrc();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.mainBox {
  .control_box {
    background-color: white;
    padding: 20px;
    min-width: 875px;
    .control_box_bottom {
      margin-top: 20px;
    }
  }
  .show_box {
    font-size: 20px;
    width: 100%;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 20px;
    margin-top: 10px;
    background-color: white;
    min-width: 875px;
    .expand_modules {
      display: flex;
      flex-wrap: wrap;
    }
    .flex_wrap {
      display: flex;
      flex-wrap: wrap;
    }
    .module_card {
      margin: 10px;
    }
  }
}
.gray-text-tip{
  font-size:10px;
  color:gray;
}
.orderTable {
  & /deep/ .ant-table-tbody > tr > td {
    border-bottom: 1px solid lightgray !important;
  }
}
.tools-title{
  padding:5px;
}
.tools-title a{
  color: #868686;
  line-height: 10px;
}
</style>